$(function () {

    //on scroll - small header
    var header = $('.header');
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        var filterHeight = $('.wrapper-filter > div').height();

        if (windowHeight - 230 > filterHeight) {
            $('body').addClass('header-sm');
        } else {
            $('body').removeClass('header-sm');
        }

        if (scroll >= 100) {
            header.addClass('header-sm');
        } else {
            header.removeClass('header-sm');
        }
    });

    $('.btn-un-filter').click(function () {
        $('.mobile-nav ul li a').not('.mnav-filter').removeClass('active');
        $('.formLoginMenu').hide();
        $('.navbar-collapse').addClass('collapse');
        $('.wrapper-filter-fields').toggleClass('active');
    });

    $('.mobile-nav .mnav-search').click(function () {
        $('.wrapper-filter-fields').removeClass('active');
        $('.mobile-nav ul li a').not('.mnav-search').removeClass('active');
        $('.formLoginMenu').hide();
        $('.navbar-collapse').addClass('collapse');
        // $('.wrapper-menu-search').toggle();
        $('.wrapper-menu-search .box-search').toggle();
        $(this).toggleClass('active');
    });

    $('.navbar-toggler').click(function () {
        $('.wrapper-menu').toggleClass('collapsed');
        $('.navbar-collapse').toggleClass('show');
    });

    $('.mobile-nav .navbar-toggler').click(function () {
        $('.wrapper-filter-fields').removeClass('active');
        $('.mobile-nav ul li a').not('.navbar-toggler').removeClass('active');
        $('.box-search').hide();
        $('.navbar-collapse').toggleClass('collapse');
        $(this).toggleClass('active');
    })
    $('.mobile-nav .btnLogin').click(function () {
        $('.wrapper-filter-fields').removeClass('active');
        $('.mobile-nav ul li a').not('.btnLogin').removeClass('active');
        $('.box-search').hide();
        $('.navbar-collapse').addClass('collapse');
        $(this).toggleClass('active');
    });


    $('#menu-main').find('.menu-item-has-children')
        .on('click', function (e) {
            e.stopPropagation();
            var itemMenuHeight = 35;
            // reset menu
            $('#menu-main .dropdown-menu').hide();
            $('#menu-main .menu-item-has-children a').removeClass('parent-hover');

            // show dropdown
            var submenu = $(this).find('.dropdown-menu');
            $(submenu).show();

            $(this).find('a').addClass('parent-hover');

            $('.wrapper-menu').attr('style', 'width:66.33%;');
            $('#menu-main').find('> li > a').attr('style', 'width:50%;');

            var mainMenuPos = $('#menu-main').offset();
            var submenuPos = $(submenu).offset();
            var submenuHeight = (submenuPos.top - mainMenuPos.top) + $(submenu).height();
            console.log(submenuHeight);
            $('.wrapper-menu .navbar-collapse').height(submenuHeight);

        });

    // Hide the menus if visible
    $(window).on('click', function () {
        $('.wrapper-menu').addClass('collapsed');
        $('.navbar-collapse').removeClass('show');
        $('#menu-main .dropdown-menu').hide();
        $('#menu-main .menu-item-has-children a').removeClass('parent-hover');
        $('header .wrapper-menu').attr('style', 'width:33.33%;');
        $('#menu-main').find('> li > a').attr('style', 'width:100%;');
        $('.wrapper-menu .navbar-collapse').attr('style', '');
    });
    $('.navbar-toggler, .wrapper-menu').on('click', function (event) {
        event.stopPropagation();
    });



    $(function () {
        // smooth scroll
        $(window).on('load', function () {
            if (window.location.hash) {
                var hash = window.location.hash;
                if ($(hash).length) {
                    var offsetTop = 80;
                    $('html, body').animate({
                        scrollTop: $(hash).offset().top - offsetTop
                    }, 500);
                }
            }
        });

        var $layoutAnchor = $('.layout-anchor');
        $('.layout-anchor a, .to-top').on('click', function (e) {
            e.preventDefault()
            var target = $(this).attr('href');
            var offsetTop = 0;
            var _this = $(this);
            if (!$(this).hasClass('to-top')) {
                offsetTop = 80;
                $layoutAnchor.find('a').removeClass('active');
                history.replaceState(undefined, undefined, target)
            }

            $('html, body').animate({
                scrollTop: $(target).offset().top - offsetTop
            }, 500, function () {
                setTimeout(function () {
                    _this.addClass('active');
                }, 100)
            });
        });

        // mobile navi
        var $burger = $('.burger');
        $burger.on('click', function (e) {
            e.preventDefault()
            $('#main-menu').toggleClass('open');
            $(this).toggleClass('open');
            $('body').toggleClass('menu-open');
        });

        $('.nav li.menu-item-has-children > a').on('click', function (e) {
            if ($burger.is(":visible") && $(e.target).is('span')) {
                e.preventDefault();
                $(e.target).parents('li').toggleClass('open-sub');
            }
        });

        // Navi
        $('#menu-main').find('.menu-item-has-children').on('hover', function () {
            $('#menu-main').find('.sub-menu-wrap').hide();
            $('#menu-main').find('> li a span').removeClass('minus');
            $(this).find('.sub-menu-wrap').show();
            $(this).find('> a span').addClass('minus');
        },
            function () {
                var current_main_item = $(this);
                setTimeout(function () {
                    $(current_main_item).find('.sub-menu-wrap').hide();
                    $(current_main_item).find('> a span').removeClass('minus');
                }, 100);
            });

        var $header = $('header');
        var headerHeight = $header.outerHeight();
        var $body = $('body');
        var scrolltop = 0;
        var showNavi = function () {
            scrolltop = $(this).scrollTop();
            if (scrolltop > headerHeight - 70) {
                $header.addClass('fixed-top');
                $body.addClass('navi-fixed');
            } else {
                $header.removeClass('fixed-top');
                $body.removeClass('navi-fixed');
            }
        }

        // hide show element
        $('[data-toggle="hideshow"]').click(function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            var $target = $($(this).attr('href'));
            $target.toggle();
        });

        $('.titleAdditionInfo').each(function () {
            $(this).on('click', function () {
                $(this).hide();
                $(this).parent().parent().find('.card-titleAddition').show();
            });
        });

    });

//    $('.un-loading').show();
    $('.lazy').Lazy();


    $('#btnUnionLogin').on('click', function (event) {
        event.preventDefault();
        $('.un-loading-login').show();
        $('.un-overlay-login-wrapper').show();
        
        // remove from URL &logout=true
        currentURL = window.location.href.split('&logout=true');
        if (currentURL.length > 1) {
            var historyURL = window.location.href.replace('&logout=true', '');
        } else {
            var historyURL = window.location.href.replace('?logout=true', '');
        }

        history.pushState({
            id: 'login',
            source: 'web'
        }, 'Login', historyURL);

        var username = $('#formLogin').find($('[name*=wp_username]'));
        var password = $('#formLogin').find($('[name*=wp_password]'));
        var isbnorean = $('#formLogin #selectedIsbnorean');
        var wp_library_id = $('#formLogin').find($('[name*=wp_library_id]'));

        var data = {
            action: 'unionLogin',
            library_id: $(wp_library_id).val(),
            user: $(username).val(),
            password: $(password).val(),
            isbnorean: $(isbnorean).val()
        };
        var ajax_url = window.ajax_url;
        $.ajax({
            type: 'POST',
            url: ajax_url,
            data: data,
            dataType: 'json',
            success: function (res) {
                if (res.success) {
                    //    console.log( res.name );
                    //    $('#ajaxfullName').html( res.name );
                    //    $('#ajaxlibraryName').html( res.libraryName );
                    //    $('#ajaxusername').html( res.barcode );
                    //    $('.un-loading-login').hide();
                    //    $('.un-overlay-login-wrapper').hide();
                    //    $('#loginModal').modal('hide');   
                    //    $('#dropdownLogin').attr('data-bs-toggle', 'dropdown' ).css('color','#236aaf').addClass('dropdown-toggle');

                    //    splitUrl = window.location.href.split('?');
                    //    if ( splitUrl.length > 0 ) {
                    //        logoutURL = window.location + '&logout=true';
                    //    } else {
                    //        logoutURL = window.location + '?logout=true';
                    //    }

                    //    $('#dropdownLoginActions').append('<li><a class="dropdown-item" href="' + logoutURL + '">Abmelden</a></li>');

                    //    $('[data-bs-dispose="true"]').tooltip('dispose');

                    //    $('.btnFernleihe').each(function() {
                    //         $(this).removeClass('btn-disabled').addClass('btn-active');
                    //    });

                    location.reload();
                    console.log('login');
                    // unionBorrw();

                } else {
                    $('.un-overlay-login-wrapper').hide();
                    $('.modal-body').append('<div class="alert alert-danger">' + res.errorMessage + '</div>');
                }
            },
            error: function (request, status, error) {
                console.log(request);
                console.log(status);
                console.log(error);
            }
        });
    });

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

    validateSearch();

});

function slider( $ID ) {
    $( '#' + $ID + ' .book-slider' ).slick({
        infinite: true,
        autoplay: false,
        variableWidth: false,
        dots: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        lazyLoad: 'ondemand',
        appendArrows: '#' + $ID + ' .wrapper-navigation',
        appendDots: '#' + $ID + ' .wrapper-navigation',        
        draggable: false,
        responsive: [
        {
        breakpoint: 960,
        settings: {
            draggable: true,
            slidesToShow: 3,
            slidesToScroll: 3
        }
        },
        {
        breakpoint: 480,
        settings: {
            draggable: true,
            slidesToShow: 2,
            slidesToScroll: 1
        }
        },                    
        ]
              
    });
    $('.lazy').Lazy();
    $('.wrapperLoading').hide();
}

function validateSearch() {
    var searchBtn = $('#union_search_btn');
    var searchInput = $('#union_search_query');
    var $form = $('#union_search');
    var $boxSearchLoading = $('.box-search-loading');
    var $clearBtn = $('.clearBtn');
    var paginationLinks = $('.paginationWrapper li a');

    if (searchInput.val()) {
        $clearBtn.show();
    } 

    paginationLinks.on('click', function () {
        $('#ajaxWrapperList').html('');
        $('.un-loading').show();
        window.scrollTo(0, 0);
    });

    searchBtn.on('click', function () {
        if (searchInput.val()) {
            $boxSearchLoading.show();
            $('.un-loading').show();
            $('#ajaxWrapperList').html('');
            $('.items-wrapper h4, .paginationWrapper').hide();
            $form.trigger('submit');
        } else {
            $(searchInput).attr('placeholder', 'Bitte geben Sie ein Stichwort ein.');
        }
    });
    searchInput.on('keyup', function (event) {
        $clearBtn.show();
        if ($(this).val() == '') {
            $clearBtn.hide();
        }
        if (event.key === 'Enter') {
            if ($(this).val() == '') {
                $(this).attr('placeholder', 'Bitte geben Sie ein Stichwort ein.');
                event.preventDefault();
            } else {    
                $('.un-loading').show();
                $boxSearchLoading.show();
                $('#ajaxWrapperList').html('');
                $('.items-wrapper h4, .paginationWrapper').hide();            
            }
        }        
    });
    $('#union_search').on('submit', function(event) {
        if ($(searchInput).val() === '') {
            $(searchInput).attr('placeholder', 'Bitte geben Sie ein Stichwort ein.');
            event.preventDefault();
        }
    });    
    $('.clearBtn').on('click', function () {
        searchInput.val('');
        $(this).hide();
    });
}

function loginRedirect( isbnorean ) {
    var redirectIsbnorean = $('#formLogin #selectedIsbnorean');
    $(redirectIsbnorean).attr('value',isbnorean);
}

function unionBorrw() {

    $('.btnFernleihe').each(function () {
        $(this).on('click', function (event) {
            event.preventDefault();
            
            var isbnorean = $(this).data('isbnorean');
            var showBorrowNotReservation = $(this).data('showBorrowNotReservation');

            $('.borrowModal .borrowAlert').show();
            $('#borrowModal .modal-title').html( $(this).parent().find('.popupTitle').html() );
            $('#borrowModal .popupDescription').html( $(this).parent().find('.popupDescription').html() );
            $('#borrowModal .mediaItem').removeClass('check');
            $('#borrowModal .borrowAlert, #btnFernleiheYes').show();
            $('#btnFernleiheNo').html('Abbrechen');

            $('#btnFernleiheYes').data('isbnorean', isbnorean);

            $('#borrowModal .modal-body .mediaItem').html('');
            $('#borrowModal .modal-body .alert-info').remove();
            var borrowBookInfo = $('<div class="row"></div>');
            var borrowBookInfoHtml = '<div class="col-4 col-lg-3">' + $('#book-' + isbnorean + ' .mediaThumb .imgWrapper').html() + '</div>';
            borrowBookInfoHtml = borrowBookInfoHtml + '<div class="col-8 col-lg-9">';
            borrowBookInfoHtml = borrowBookInfoHtml + '<h4>' + $('#book-' + isbnorean + ' .card-title').html() + '</h4>';
            borrowBookInfoHtml = borrowBookInfoHtml + '<h5>' + $('#book-' + isbnorean + ' .card-autor').html() + '</h5>';
            borrowBookInfoHtml = borrowBookInfoHtml + '<div class="borrowModalResponse">';
            //borrowBookInfoHtml = borrowBookInfoHtml + '<button class="btn btn-primary btn-loading" type="button" disabled><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>  Loading...</button>';
            borrowBookInfoHtml = borrowBookInfoHtml + '<div class="btn-loading"><span></span>  suchen...</button>';
            borrowBookInfoHtml = borrowBookInfoHtml + '</div>';
            borrowBookInfoHtml = borrowBookInfoHtml + '</div>';

            $(borrowBookInfo).prepend(borrowBookInfoHtml);
            $('#borrowModal .modal-body .mediaItem').prepend(borrowBookInfo);
            $('#borrowModal .modal-body .btn-loading').hide();
            const borrowModal = new bootstrap.Modal('#borrowModal', {
                keyboard: false
            });
            borrowModal.show();
        });
    });

    $('#btnFernleiheYes').on('click', function (event) {
        event.preventDefault();
        $('#borrowModal .modal-body .btn-loading').show();

        var isbnorean = $(this).data('isbnorean');

        var data = {
            action: 'unionBarrow',
            isbnorean: isbnorean,
        };

        $.ajax({
            type: 'GET',
            url: ajax_url,
            data: data,
            dataType: 'json',
            success: function (res) {
                if (res.success) {

                    var isbnoreanLabel = res.isbn ? 'ISBN: ' + res.isbn : 'EAN: ' + res.ean;
                    var borrowModalResponse = $('#borrowModal .modal-body .mediaItem .borrowModalResponse');
                    var borrowBookInfoHtml = '<div></div>';

                    $(borrowModalResponse).html('');
                    borrowBookInfoHtml = borrowBookInfoHtml + '<h5 class="LibraryName">' + $('.bi-building')[0].outerHTML + res.sourceLibraryName + '</h5>';
                    borrowBookInfoHtml = borrowBookInfoHtml + '<h5 class="Barcode">' + $('.bi-upc')[0].outerHTML + res.mediaBarcode + '</h5>';
                    borrowBookInfoHtml = borrowBookInfoHtml + '<h5>' + isbnoreanLabel + '</h5>';
                    //borrowBookInfoHtml = borrowBookInfoHtml + '<p style="color : #198754; font-size : 18px; margin : 20px 0;" class="mb-0">' + res.errorMessage + '</p>';
                    $(borrowModalResponse).prepend(borrowBookInfoHtml);
                    $('#borrowModal .mediaItem').addClass('check');

                    $('#borrowModal .modal-title').html(res.errorMessage);
                    if (!res.mailWasSentToPatron) {
                        $('#borrowModal .modal-body').prepend('<div class="alert alert-info">' + mailwassenttopatron_message + '</div>');
                    }
                    $('button[data-isbnorean="'+isbnorean+'"]').hide(); 
                    $('button[data-isbnorean="'+isbnorean+'"]').parent().parent().parent().parent().append('<span class="badge text-bg-success">Sie haben diesen Titel bereits bestellt.</span>');
                    $('button[data-isbnorean="'+isbnorean+'"]').off('click');
                    $('#btnFernleiheNo').html('Schließen');
                    $('#btnFernleiheYes').hide();

                    // $('#borrowModal .borrowAlert, #btnFernleiheYes').hide();

                } else {
                    console.log(res.errorMessage);
                }
            },
            error: function (request, status, error) {
                console.log(request);
                console.log(status);
                console.log(error);
            }
        });

    });



}

function setCookieMinutes(cname, cvalue, minutes) {
    var d = new Date();
    d.setTime(d.getTime() + (minutes * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}


